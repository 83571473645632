export default () => ({
    cartId: null,
    cartData: null,
    complexProductDataItems: [],
    isCartDataValid: false,
    isCartDataLoading: true,
    isAddToCartPending: false,
    loaderText: '',
    isLoaderVisible: false,
    padData: null,
    invalidCouponData: {},
    wishlistProducts: [],
    giftCardData: null,
});
