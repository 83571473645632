<template>
    <div
        :class="{
            'side-modal-mask': isOpenFlag && !applyOnlyOnMobile,
            'bg-filter': isBackgroundBlurred,
        }"
        @keyup.esc.stop="close()"
    >
        <WithClickOutsideDetection :handler="isOpenFlag ? 'clickOutside' : null">
            <div
                ref="sideModal"
                :data-test-id="SIDE_MODAL"
                :tabindex="tabindex"
                :class="additionalClasses"
                class="side-modal"
            >
                <WithScroll
                    ref="modalWrapper"
                    :is-enabled="isGlobalScrollEnabled"
                    class="wrapper modal-wrapper"
                >
                    <div class="header">
                        <slot name="header-left-icon" />

                        <slot name="header">
                            <div v-if="label" class="label">
                                <BaseHeading tag="h5" class="heading">
                                    {{ label }}
                                </BaseHeading>
                            </div>

                            <ButtonIcon
                                :variant="BUTTON_VARIANT"
                                :data-test-id="SIDE_MODAL_CLOSE"
                                class="close"
                                @click.native="close()"
                            >
                                <Icon :icon="Close" />
                            </ButtonIcon>
                        </slot>
                    </div>

                    <WithScroll ref="body" :is-enabled="scrollableBody" class="body-text">
                        <slot />
                    </WithScroll>

                    <div v-if="$slots.footer" class="footer">
                        <slot name="footer" />
                    </div>
                </WithScroll>
            </div>
        </WithClickOutsideDetection>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import { PREVENT_SCROLL_CLASS_NAME } from '@configs/class-names';

import { SLIDE_FROM_TYPES } from '@types/SideModal';
import { SIDE_MODAL, SIDE_MODAL_CLOSE } from '@types/AutomaticTestIDs';

import { checkIfExistsInValuesMap } from '@assets/props';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import WithScroll from '@atoms/WithScroll/WithScroll';

import WithClickOutsideDetection from '@molecules/WithClickOutsideDetection/WithClickOutsideDetection';

import { Close } from '@modivo-ui/icons/v2/navigation';
import { Icon } from '@modivo-ui/components/Icon/v1';
import { ButtonIcon, BUTTON_ICON_VARIANTS } from '@modivo-ui/components/ButtonIcon/v1';

const { mapActions: mapActionsLayout } = createNamespacedHelpers('layout');

export default {
    name: 'SideModal',

    components: {
        Icon,
        WithClickOutsideDetection,
        BaseHeading,
        WithScroll,
        ButtonIcon,
    },

    props: {
        label: {
            type: String,
            default: '',
        },

        isOpen: {
            type: Boolean,
            default: false,
        },

        applyOnlyOnMobile: {
            type: Boolean,
            default: false,
        },

        slideFrom: {
            type: String,
            default: 'left',
            validator: checkIfExistsInValuesMap(SLIDE_FROM_TYPES, true),
        },

        applyNoScroll: {
            type: Boolean,
            default: true,
        },

        deferOpenToMount: {
            type: Boolean,
            default: false,
        },

        fixedHeader: {
            type: Boolean,
            default: false,
        },

        absoluteHeader: {
            type: Boolean,
            default: false,
        },

        scrollableBody: {
            type: Boolean,
            default: true,
        },

        stickyFooter: {
            type: Boolean,
            default: false,
        },

        theme: {
            type: String,
            default: '',
        },

        isNotFullScreenOnMobile: {
            type: Boolean,
            default: false,
        },

        isBackgroundBlurred: {
            type: Boolean,
            default: false,
        },

        scrollPosition: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            isMounted: false,
            isMountedAfterAnimationFrame: false,
        };
    },

    computed: {
        ...mapState(['isMobile']),

        isOpenFlag() {
            return this.deferOpenToMount ? this.isMounted && this.isOpen : this.isOpen;
        },

        isOpenedClass() {
            return this.deferOpenToMount
                ? this.isOpenFlag && this.isMountedAfterAnimationFrame
                : this.isOpenFlag;
        },

        additionalClasses() {
            return {
                opened: this.isOpenedClass,
                [this.slideFrom]: true,
                'mobile-only': this.applyOnlyOnMobile,
                'fixed-header': this.fixedHeader,
                'absolute-header': this.absoluteHeader,
                'sticky-footer': this.stickyFooter,
                [this.theme]: true,
                'is-not-full-screen-on-mobile': this.isNotFullScreenOnMobile,
            };
        },

        tabindex() {
            return this.isOpenFlag ? '-1' : null;
        },

        isGlobalScrollEnabled() {
            if (this.scrollableBody) {
                return false;
            }

            return !this.applyOnlyOnMobile || this.isMobile;
        },
    },

    watch: {
        isOpenFlag(open) {
            this.$nextTick(() => {
                if (open) {
                    if (this.applyNoScroll) {
                        this.addHTMLClasses([PREVENT_SCROLL_CLASS_NAME]);
                    }

                    this.$refs.sideModal.focus();
                } else {
                    if (this.applyNoScroll) {
                        this.removeHTMLClasses([PREVENT_SCROLL_CLASS_NAME]);
                    }

                    this.$refs.modalWrapper.$el.scrollTop = 0;
                    this.$refs.sideModal.blur();
                }
            });
        },

        scrollPosition(newPosition) {
            this.scrollBody(newPosition);
        },
    },

    beforeCreate() {
        this.SIDE_MODAL = SIDE_MODAL;
        this.SIDE_MODAL_CLOSE = SIDE_MODAL_CLOSE;
        this.BUTTON_VARIANT = BUTTON_ICON_VARIANTS.TERTIARY;
        this.Close = Close;
    },

    mounted() {
        this.isMounted = true;

        window.requestAnimationFrame(() => {
            this.isMountedAfterAnimationFrame = true;
        });
    },

    destroyed() {
        if (this.applyNoScroll) {
            this.removeHTMLClasses([PREVENT_SCROLL_CLASS_NAME]);
        }
    },

    methods: {
        ...mapActionsLayout(['addHTMLClasses', 'removeHTMLClasses']),

        close() {
            this.$emit('close');
        },

        // eslint-disable-next-line vue/no-unused-properties
        clickOutside() {
            this.$emit('click-outside');
        },

        scrollBody(position) {
            this.$refs.body.$el.scrollTop = position;
        },
    },
};
</script>

<style lang="scss" scoped>
$shadowGap: 20px;

.side-modal {
    z-index: theme('zIndex.3');

    &:deep() {
        .body-text.with-scroll,
        .modal-wrapper.with-scroll {
            @apply max-h-none h-full relative;
        }
    }
}

@mixin horizontal-screen-slide() {
    &.left,
    &.right {
        top: 0;

        &.opened {
            transform: translateX(0);
        }
    }

    &.left {
        left: 0;
        transform: translateX(-100%);
    }

    &.right {
        right: 0;

        transform: translateX(100%);
    }
}

@mixin horizontal-screen-slide-desktop() {
    &.left,
    &.right {
        top: 0;
        width: theme('customVariables.sideModal.desktopWidth');
        height: 100%;

        &.opened {
            transform: translateX(0);
        }
    }

    &.left {
        left: 0;
        transform: translateX(calc(-100% - #{$shadowGap}));
    }

    &.right {
        right: 0;
        transform: translateX(calc(100% + #{$shadowGap}));
    }
}

@mixin vertical-screen-slide() {
    &.top,
    &.bottom {
        @apply left-0 w-full;

        &.opened {
            transform: translateY(0);
        }
    }

    &.top {
        @apply top-0;

        transform: translateY(-100%);
    }

    &.bottom {
        @apply bottom-0;

        transform: translateY(100%);
    }
}

@mixin vertical-screen-slide-desktop() {
    &.top,
    &.bottom {
        left: 0;
        width: 100%;
        height: theme('customVariables.sideModal.desktopHeight');
    }

    &.top {
        top: calc(-1 * #{theme('customVariables.sideModal.desktopHeight')} - #{$shadowGap});

        &.opened {
            transform: translateY(
                calc(theme('customVariables.sideModal.desktopHeight') + #{$shadowGap})
            );
        }
    }

    &.bottom {
        bottom: calc(-1 * #{theme('customVariables.sideModal.desktopHeight')} - #{$shadowGap});

        &.opened {
            transform: translateY(
                calc(-1 * #{theme('customVariables.sideModal.desktopHeight')} - #{$shadowGap})
            );
        }
    }
}

@mixin desktop-slides() {
    @include horizontal-screen-slide-desktop();
    @include vertical-screen-slide-desktop();
}

@mixin desktop-screen-styles() {
    @include desktop-slides();
    @apply shadow-4;
}

@mixin slide-screen-styles() {
    @include horizontal-screen-slide();
    @include vertical-screen-slide();

    @apply bg-light fixed w-full h-full overflow-y-hidden;
    transition: transform 0.2s ease-in;

    &.opened {
        @apply z-7;

        transition: transform 0.3s ease-out;
    }

    &.fixed-header {
        @apply overflow-y-hidden;
        $modal-header-height: #{theme('customVariables.sideModal.headerHeight')};

        .header {
            @apply mb-0;
        }

        .body-text {
            @apply p-3;
        }
    }

    .header {
        @apply flex flex-row justify-between items-start w-full flex-shrink-0;
        height: #{theme('customVariables.sideModal.headerHeight')};

        .close {
            @apply z-ui-1;
        }
    }

    .body-text {
        @apply flex-grow;
    }

    .footer {
        @apply bg-light;
    }
}

.side-modal {
    @apply outline-none;

    .wrapper {
        @apply w-full h-full flex flex-col;
    }

    .header {
        @apply hidden w-full;
    }

    &:not(.mobile-only) {
        @include slide-screen-styles();

        @screen lg {
            @include desktop-screen-styles();
        }
    }

    &.mobile-only {
        @screen mobile-only {
            @include slide-screen-styles();
        }
    }

    &.absolute-header {
        .header {
            @apply absolute;
        }
    }

    &.sticky-footer {
        .wrapper {
            @apply min-h-full h-auto;
        }

        .footer {
            @apply sticky bottom-0;
        }
    }
}

@screen mobile-only {
    .bottom,
    .top {
        &.is-not-full-screen-on-mobile {
            @apply h-3/4 w-full max-w-full;

            @screen md {
                @apply h-full rounded-t-ds-container-edge;
                max-width: theme('customVariables.sideModal.desktopWidth');
            }
        }
    }

    .bottom {
        @apply rounded-t-ds-container-small;
    }

    .top {
        @apply rounded-b-ds-container-small;
    }
}

.side-modal-mask {
    &::before {
        @apply fixed top-0 left-0 w-full h-full bg-dark bg-opacity-75;
        content: '';
        z-index: theme('zIndex.3');
    }

    &:focus {
        @apply outline-none;
    }
}

.bg-filter {
    &::before {
        @apply z-4;
        backdrop-filter: blur(6px);
    }
}
</style>
