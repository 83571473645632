<template>
    <section class="marketing-blog-bar">
        <BaseHeading class="tagline">
            {{ tagline }}
        </BaseHeading>
        <BaseHeading class="heading" tag="h2" theme="h2">
            {{ heading }}
        </BaseHeading>
        <BaseParagraph class="description">
            {{ description }}
        </BaseParagraph>
        <BaseButton :theme="THEME_THIN_LIGHT" class="action" :to="buttonLink">
            {{ buttonLabel }}
        </BaseButton>
    </section>
</template>

<script>
import { THEME_THIN_LIGHT } from '@types/Button';
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import BaseButton from '@atoms/BaseButton/BaseButton';

export default {
    name: 'MarketingBlogBar',

    components: {
        BaseHeading,
        BaseParagraph,
        BaseButton,
    },

    props: {
        tagline: {
            type: String,
            required: true,
        },

        heading: {
            type: String,
            required: true,
        },

        description: {
            type: String,
            required: true,
        },

        buttonLabel: {
            type: String,
            required: true,
        },

        buttonLink: {
            type: String,
            required: true,
        },
    },

    beforeCreate() {
        this.THEME_THIN_LIGHT = THEME_THIN_LIGHT;
    },
};
</script>

<style lang="scss" scoped>
.marketing-blog-bar {
    @apply text-center p-5 bg-ds-content-accent;

    .tagline {
        @apply block text-s leading-s text-light;
        letter-spacing: theme('customVariables.marketingBlogBar.headingLetterSpacing');
    }

    .heading,
    .description {
        @apply block mt-3 mx-auto text-light text-center;
        max-width: theme('customVariables.marketingBlogBar.textMaxWidth');
    }

    .action {
        @apply mt-4;
    }

    @screen md {
        .heading,
        .description {
            @apply mt-2;
        }
    }
}
</style>
