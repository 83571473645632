export const ABTEST_COOKIE_NAME = 'abtest';

export const AB_TEST_COOKIE_VALUE_SEPARATOR = ':';
export const AB_TEST_COOKIE_VARIANT_SEPARATOR = '=';

export const AB_TEST_SEARCH_COOKIE_SUFFIX = '_SBE';

export const X_SEARCH_ABTEST_HEADER_NAME = 'x-search-abtest';

export const COOKIE_SEARCH_SERVICE_ENGINE = 'mod_all_plp_searchServiceEngine';
export const COOKIE_AUTOCOMPLETE_POC_CATEGORIES = 'mod_all_plp_autocompletePocCategories';
export const COOKIE_AUTOCOMPLETE_POC_PHRASES = 'mod_all_plp_autocompletePocPhrases';
export const COOKIE_SEARCH_BY_CATEGORY = 'mod_all_plp_searchByCategory';
export const SEARCH_SERVICE_VISUAL_SEARCH = 'mod_all_plp_visual_search';
export const VISUAL_SEARCH_BY_IMG = 'mod_all_plp_visual_search_input';
export const VISUAL_SEARCH_BY_IMG_CAMERA_BTN = 'mod_all_plp_visual_search_input_camera_btn';
export const SEARCH_SERVICE_ACTION_LISTINGS = 'mod_pl_plp_browse_actions';

export const PWA_LISTING_CACHE_COOKIE_NAME = 'pwa_listing_cache';
