<template>
    <header class="header-simple-with-back-button">
        <ContainerContent class="wrapper">
            <ButtonIcon :variant="BUTTON_ICON_VARIANT" @click="$router.back()">
                <Icon :icon="ChevronLeft" />
            </ButtonIcon>

            <BaseLink :path="homePagePath">
                <img :src="brandLogoSrc" :alt="brandLogoAlt" width="130" height="40" />
            </BaseLink>
        </ContainerContent>

        <Divider class="divider" />
    </header>
</template>

<script>
import { BRAND_LOGO, BRAND_SITE_NAME } from '@localeConfig/keys';

import BaseLink from '@atoms/BaseLink/BaseLink';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';

import { ButtonIcon, BUTTON_ICON_VARIANTS } from '@modivo-ui/components/ButtonIcon/v1';

import { ChevronLeft } from '@modivo-ui/icons/v2/navigation';
import { Icon } from '@modivo-ui/components/Icon/v1';
import { Divider } from '@modivo-ui/components/Divider/v2';

export default {
    name: 'HeaderSimple',

    components: {
        ContainerContent,
        BaseLink,
        ButtonIcon,
        Icon,
        Divider,
    },

    computed: {
        homePagePath() {
            return this.$navigationContext.getContextPath();
        },
    },

    beforeCreate() {
        this.ChevronLeft = ChevronLeft;
        this.brandLogoSrc = this.$configProvider.getConfig(BRAND_LOGO);
        this.brandLogoAlt = this.$configProvider.getConfig(BRAND_SITE_NAME);
        this.BUTTON_ICON_VARIANT = BUTTON_ICON_VARIANTS.TERTIARY;
    },
};
</script>

<style lang="scss" scoped>
.header-simple-with-back-button {
    @apply bg-ds-container-primary;

    height: theme('customVariables.headerSimpleWithBackButton.height');

    .wrapper {
        @apply flex flex-row w-full h-full justify-start items-center gap-ui-2;
    }

    .divider {
        @apply -mt-ui-plus-1p;
    }

    @screen lg {
        .divider {
            @apply hidden;
        }
    }
}
</style>
