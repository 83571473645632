import { SHIPPING_METHODS_DELIVERY_TIME } from '@localeConfig/keys';

import ACS from '@static/icons/56px/acs.svg?inline';
import Balikovna from '@static/icons/56px/balikovna.svg?inline';
import Boxnow from '@static/icons/56px/boxnow.svg?inline';
import BRT from '@static/icons/56px/BRT.svg?inline';
import DHL from '@static/icons/56px/dhl.svg?inline';
import DPD from '@static/icons/56px/DPD.svg?inline';
import Inpost from '@static/icons/56px/inpost.svg?inline';
import Transport2 from '@static/icons/48px/transport2.svg?inline';
import Ruch from '@static/icons/56px/ruch.svg?inline';
import Speedy from '@static/icons/56px/speedy.svg?inline';
import CeskaPosta from '@static/icons/56px/ceska-posta.svg?inline';
import GenikiTachydromiki from '@static/icons/56px/geniki-tachydromiki.svg?inline';
import GLS from '@static/icons/56px/GLS.svg?inline';
import Hermes from '@static/icons/56px/hermes.svg?inline';
import LaPoste from '@static/icons/56px/laposte.svg?inline';
import PPL from '@static/icons/56px/PPL.svg?inline';
import PPLPudo from '@static/icons/56px/pplpudo.svg?inline';
import MagyarPosta from '@static/icons/56px/magyar-posta.svg?inline';
import ModivoPickup from '@static/icons/custom/modivo-pickup.svg?inline';
import Meest from '@static/icons/56px/meest.svg?inline';
import NovaPoshta from '@static/icons/56px/novaposhta.svg?inline';
import Easybox from '@static/icons/custom/easybox.svg?inline';
import Foxpost from '@static/icons/custom/foxpost.svg?inline';
import GlsParcelshop from '@static/icons/56px/glsparcelshop.svg?inline';
import PosteItaliane from '@static/icons/56px/posteitalianepudo.svg?inline';
import Venipak from '@static/icons/56px/venipak.svg?inline';
import Zasilkovna from '@static/icons/custom/zasilkovna.svg?inline';

import defaultClusterIcon from '@static/map/cluster.svg';
import defaultMarkerIcon from '@static/map/marker.svg';
import defaultActiveMarkerIcon from '@static/map/marker-active.svg';

export const DEFAULT_SHIPPING_METHOD_CARRIER_CODE = 'default';
export const DEFAULT_MARKERS = 'defaultMarkers';

export const ACS_PUDO_SHIPPING_METHOD_CARRIER_CODE = 'acspudo';
export const BALIKOVNA_SHIPPING_METHOD_CARRIER_CODE = 'balikovna';
export const BOXNOW_SHIPPING_METHOD_CARRIER_CODE = 'boxnow';
export const CLICK_AND_COLLECT_SHIPPING_METHOD_CARRIER_CODE = 'clickandcollect';
export const DPD_SHIPPING_METHOD_CARRIER_CODE = 'dpd';
export const INPOST_SHIPPING_METHOD_CARRIER_CODE = 'inpost';
export const DPD_POINTS_SHIPPING_METHOD_CARRIER_CODE = 'dpdparcelshop';
export const EASYBOX_POINTS_SHIPPING_METHOD_CARRIER_CODE = 'easybox';
export const ECONT_POINTS_SHIPPING_METHOD_CARRIER_CODE = 'econt';
export const FOXPOST_POINTS_SHIPPING_METHOD_CARRIER_CODE = 'foxpost';
export const GLS_PARCELSHOP_POINTS_SHIPPING_METHOD_CARRIER_CODE = 'glsparcelshop';
export const HERMES_PUDO_POINTS_SHIPPING_METHOD_CARRIER_CODE = 'hermespudo';
export const LAPOSTE_PUDO_SHIPPING_METHOD_CARRIER_CODE = 'lapostepudo';
export const MAERSK_LAPOSTE_PUDO_SHIPPING_METHOD_CARRIER_CODE = 'maersklapostepudo';
export const MAGYAR_POSTA_SHIPPING_METHOD_CARRIER_CODE = 'magyar';
export const NOVA_POSHTA_PUDO_SHIPPING_METHOD_CARRIER_CODE = 'novaposhtapudo';
export const POSTE_ITALIANE_PUDO_POINTS_SHIPPING_METHOD_CARRIER_CODE = 'posteitalianepudo';
export const PPL_PUDO_SHIPPING_METHOD_CARRIER_CODE = 'pplpudo';
export const SPEEDY_POINTS_SHIPPING_METHOD_CARRIER_CODE = 'speedyparcelshop';
export const VENIPAK_POINTS_SHIPPING_METHOD_CARRIER_CODE = 'venipak';
export const ZASILKOVNA_POINTS_SHIPPING_METHOD_CARRIER_CODE = 'zasilkovna';

export const POINT_CARRIER_CODES = [
    ACS_PUDO_SHIPPING_METHOD_CARRIER_CODE,
    INPOST_SHIPPING_METHOD_CARRIER_CODE,
    BALIKOVNA_SHIPPING_METHOD_CARRIER_CODE,
    BOXNOW_SHIPPING_METHOD_CARRIER_CODE,
    CLICK_AND_COLLECT_SHIPPING_METHOD_CARRIER_CODE,
    DPD_POINTS_SHIPPING_METHOD_CARRIER_CODE,
    EASYBOX_POINTS_SHIPPING_METHOD_CARRIER_CODE,
    ECONT_POINTS_SHIPPING_METHOD_CARRIER_CODE,
    FOXPOST_POINTS_SHIPPING_METHOD_CARRIER_CODE,
    GLS_PARCELSHOP_POINTS_SHIPPING_METHOD_CARRIER_CODE,
    HERMES_PUDO_POINTS_SHIPPING_METHOD_CARRIER_CODE,
    LAPOSTE_PUDO_SHIPPING_METHOD_CARRIER_CODE,
    MAERSK_LAPOSTE_PUDO_SHIPPING_METHOD_CARRIER_CODE,
    MAGYAR_POSTA_SHIPPING_METHOD_CARRIER_CODE,
    NOVA_POSHTA_PUDO_SHIPPING_METHOD_CARRIER_CODE,
    POSTE_ITALIANE_PUDO_POINTS_SHIPPING_METHOD_CARRIER_CODE,
    PPL_PUDO_SHIPPING_METHOD_CARRIER_CODE,
    SPEEDY_POINTS_SHIPPING_METHOD_CARRIER_CODE,
    VENIPAK_POINTS_SHIPPING_METHOD_CARRIER_CODE,
    ZASILKOVNA_POINTS_SHIPPING_METHOD_CARRIER_CODE,
];

export const SHIPPING_METHOD_CODES_WITH_DEDICATED_COMPONENT = {
    ACS_PUDO_SHIPPING_METHOD_CARRIER_CODE,
    INPOST_SHIPPING_METHOD_CARRIER_CODE,
    BALIKOVNA_SHIPPING_METHOD_CARRIER_CODE,
    BOXNOW_SHIPPING_METHOD_CARRIER_CODE,
    CLICK_AND_COLLECT_SHIPPING_METHOD_CARRIER_CODE,
    DPD_POINTS_SHIPPING_METHOD_CARRIER_CODE,
    EASYBOX_POINTS_SHIPPING_METHOD_CARRIER_CODE,
    ECONT_POINTS_SHIPPING_METHOD_CARRIER_CODE,
    FOXPOST_POINTS_SHIPPING_METHOD_CARRIER_CODE,
    GLS_PARCELSHOP_POINTS_SHIPPING_METHOD_CARRIER_CODE,
    HERMES_PUDO_POINTS_SHIPPING_METHOD_CARRIER_CODE,
    LAPOSTE_PUDO_SHIPPING_METHOD_CARRIER_CODE,
    MAERSK_LAPOSTE_PUDO_SHIPPING_METHOD_CARRIER_CODE,
    MAGYAR_POSTA_SHIPPING_METHOD_CARRIER_CODE,
    NOVA_POSHTA_PUDO_SHIPPING_METHOD_CARRIER_CODE,
    POSTE_ITALIANE_PUDO_POINTS_SHIPPING_METHOD_CARRIER_CODE,
    PPL_PUDO_SHIPPING_METHOD_CARRIER_CODE,
    SPEEDY_POINTS_SHIPPING_METHOD_CARRIER_CODE,
    VENIPAK_POINTS_SHIPPING_METHOD_CARRIER_CODE,
    ZASILKOVNA_POINTS_SHIPPING_METHOD_CARRIER_CODE,
};

export const SHIPPING_METHOD_ICONS_MAP = {
    acspudo: ACS,
    balikovna: Balikovna,
    boxnow: Boxnow,
    clickandcollect: ModivoPickup,
    dhl: DHL,
    inpost: Inpost,
    flatrate: Transport2,
    ruch: Ruch,
    default: Transport2,
    brt: BRT,
    speedy: Speedy,
    speedyparcelshop: Speedy,
    dpd: DPD,
    dpdparcelshop: DPD,
    ceskaposta: CeskaPosta,
    genikitachydromiki: GenikiTachydromiki,
    gls: GLS,
    hermespudo: Hermes,
    laposte: LaPoste,
    lapostepudo: LaPoste,
    maersklapostepudo: LaPoste,
    magyar: MagyarPosta,
    ppl: PPL,
    pplpudo: PPLPudo,
    storePickup: ModivoPickup,
    meest: Meest,
    novaposhtapudo: NovaPoshta,
    easybox: Easybox,
    econt: Transport2,
    foxpost: Foxpost,
    glsparcelshop: GlsParcelshop,
    posteitalianepudo: PosteItaliane,
    venipak: Venipak,
    zasilkovna: Zasilkovna,
};

export const MAP_MARKERS = [
    {
        name: DEFAULT_MARKERS,
        clusterIcon: defaultClusterIcon,
        markerIcon: defaultMarkerIcon,
        activeMarkerIcon: defaultActiveMarkerIcon,
    },
];

export const SHIPPING_METHODS_DELIVERY_TIME_DEFAULT = {
    [SHIPPING_METHODS_DELIVERY_TIME.MIN_NAME]: 1,
    [SHIPPING_METHODS_DELIVERY_TIME.MAX_NAME]: 2,
};

export const CARRIER_CODES_POINT_FIELDS = {
    [INPOST_SHIPPING_METHOD_CARRIER_CODE]: 'inpostPoint',
    [DPD_POINTS_SHIPPING_METHOD_CARRIER_CODE]: 'dpdParcelShopPoint',
    [SPEEDY_POINTS_SHIPPING_METHOD_CARRIER_CODE]: 'speedyParcelShopPoint',
};

export const CARRIER_CODES_WITH_POINT_HANDLERS = [
    DEFAULT_SHIPPING_METHOD_CARRIER_CODE,
    INPOST_SHIPPING_METHOD_CARRIER_CODE,
    DPD_POINTS_SHIPPING_METHOD_CARRIER_CODE,
    LAPOSTE_PUDO_SHIPPING_METHOD_CARRIER_CODE,
    SPEEDY_POINTS_SHIPPING_METHOD_CARRIER_CODE,
];
