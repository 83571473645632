import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_pluginstimingstart_5c03b7a6 from 'nuxt_plugin_pluginstimingstart_5c03b7a6' // Source: ../plugins/performance/plugins-timing-start.server (mode: 'server')
import nuxt_plugin_markpluginsstart_f2315868 from 'nuxt_plugin_markpluginsstart_f2315868' // Source: ../plugins/performance/mark-plugins-start.client (mode: 'client')
import nuxt_plugin_abtests_5a7f2e0e from 'nuxt_plugin_abtests_5a7f2e0e' // Source: ../plugins/ab-tests (mode: 'all')
import nuxt_plugin_x3configprovider_438a352e from 'nuxt_plugin_x3configprovider_438a352e' // Source: ../plugins/x3-config-provider.server (mode: 'server')
import nuxt_plugin_x3configprovider_289af9b6 from 'nuxt_plugin_x3configprovider_289af9b6' // Source: ../plugins/x3-config-provider.client (mode: 'client')
import nuxt_plugin_sentryserver_394f2b2c from 'nuxt_plugin_sentryserver_394f2b2c' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_7d9ef8e2 from 'nuxt_plugin_sentryclient_7d9ef8e2' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_staticfoldershashesplugin32157be9_02e676aa from 'nuxt_plugin_staticfoldershashesplugin32157be9_02e676aa' // Source: ./static-folders-hashes.plugin.32157be9.js (mode: 'all')
import nuxt_plugin_pluginspathhelperse09eeb70_36fb8c0c from 'nuxt_plugin_pluginspathhelperse09eeb70_36fb8c0c' // Source: ./plugins.path-helpers.e09eeb70.js (mode: 'all')
import nuxt_plugin_nuxtcorrelation_4c450d3a from 'nuxt_plugin_nuxtcorrelation_4c450d3a' // Source: ./nuxt-correlation.js (mode: 'all')
import nuxt_plugin_nuxtanalyticsclient_76f438ff from 'nuxt_plugin_nuxtanalyticsclient_76f438ff' // Source: ./nuxt-analytics.client.js (mode: 'client')
import nuxt_plugin_nuxtmodals_3eaf3dac from 'nuxt_plugin_nuxtmodals_3eaf3dac' // Source: ./nuxt-modals.js (mode: 'all')
import nuxt_plugin_filterhandler_3da7fe9d from 'nuxt_plugin_filterhandler_3da7fe9d' // Source: ./filter-handler.js (mode: 'all')
import nuxt_plugin_nuxtrumspeedcurveplugin_2e871c87 from 'nuxt_plugin_nuxtrumspeedcurveplugin_2e871c87' // Source: ./nuxt-rum-speedcurve-plugin.js (mode: 'all')
import nuxt_plugin_nuxtuseragent_08e203ef from 'nuxt_plugin_nuxtuseragent_08e203ef' // Source: ./nuxt-user-agent.js (mode: 'all')
import nuxt_plugin_vuescrollto_35496ea3 from 'nuxt_plugin_vuescrollto_35496ea3' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_smoothscrollpolyfill_d5811bde from 'nuxt_plugin_smoothscrollpolyfill_d5811bde' // Source: ./nuxt-polyfill/smoothscroll-polyfill.js (mode: 'all')
import nuxt_plugin_srcpluginclient07628a4c_22d37ea5 from 'nuxt_plugin_srcpluginclient07628a4c_22d37ea5' // Source: ./src.plugin.client.07628a4c.js (mode: 'client')
import nuxt_plugin_srcpluginserver6d1bfbd4_5011be26 from 'nuxt_plugin_srcpluginserver6d1bfbd4_5011be26' // Source: ./src.plugin.server.6d1bfbd4.js (mode: 'server')
import nuxt_plugin_srcplugin30da31e0_8a2b7d30 from 'nuxt_plugin_srcplugin30da31e0_8a2b7d30' // Source: ./src.plugin.30da31e0.js (mode: 'all')
import nuxt_plugin_templatesworkboxd1e23392_cae43754 from 'nuxt_plugin_templatesworkboxd1e23392_cae43754' // Source: ./templates.workbox.d1e23392.js (mode: 'client')
import nuxt_plugin_srcplugin53f2c960_6405a85e from 'nuxt_plugin_srcplugin53f2c960_6405a85e' // Source: ./src.plugin.53f2c960.js (mode: 'all')
import nuxt_plugin_gtm_25512e08 from 'nuxt_plugin_gtm_25512e08' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_uuid_7bbe03b6 from 'nuxt_plugin_uuid_7bbe03b6' // Source: ../plugins/uuid.client (mode: 'client')
import nuxt_plugin_googleconsentmodedefault_31a576e8 from 'nuxt_plugin_googleconsentmodedefault_31a576e8' // Source: ../plugins/google-consent-mode-default.server (mode: 'server')
import nuxt_plugin_gtminit_0fc08a83 from 'nuxt_plugin_gtminit_0fc08a83' // Source: ../plugins/gtm-init.client (mode: 'client')
import nuxt_plugin_rumspeedcurve_bf3bd054 from 'nuxt_plugin_rumspeedcurve_bf3bd054' // Source: ../plugins/performance/rum-speedcurve.client (mode: 'client')
import nuxt_plugin_marknuxtready_7d47d618 from 'nuxt_plugin_marknuxtready_7d47d618' // Source: ../plugins/performance/mark-nuxt-ready.client (mode: 'client')
import nuxt_plugin_performancerecorder_77bf73d3 from 'nuxt_plugin_performancerecorder_77bf73d3' // Source: ../plugins/performance/performance-recorder (mode: 'all')
import nuxt_plugin_pagereferrer_75a31f56 from 'nuxt_plugin_pagereferrer_75a31f56' // Source: ../plugins/page-referrer.client (mode: 'client')
import nuxt_plugin_beforeinstallpromptlistener_ce019842 from 'nuxt_plugin_beforeinstallpromptlistener_ce019842' // Source: ../plugins/beforeinstallprompt-listener.client (mode: 'client')
import nuxt_plugin_errorhandler_11af5f8c from 'nuxt_plugin_errorhandler_11af5f8c' // Source: ../plugins/error-handler (mode: 'all')
import nuxt_plugin_pathhelper_d4b9f9c6 from 'nuxt_plugin_pathhelper_d4b9f9c6' // Source: ../plugins/path-helper (mode: 'all')
import nuxt_plugin_i18n_d9e26992 from 'nuxt_plugin_i18n_d9e26992' // Source: ../plugins/i18n (mode: 'all')
import nuxt_plugin_imaginator_2938a71e from 'nuxt_plugin_imaginator_2938a71e' // Source: ../plugins/imaginator (mode: 'all')
import nuxt_plugin_datehelper_63471b74 from 'nuxt_plugin_datehelper_63471b74' // Source: ../plugins/date-helper (mode: 'all')
import nuxt_plugin_cookies_7600aed6 from 'nuxt_plugin_cookies_7600aed6' // Source: ../plugins/cookies (mode: 'all')
import nuxt_plugin_storage_e473a712 from 'nuxt_plugin_storage_e473a712' // Source: ../plugins/storage.client (mode: 'client')
import nuxt_plugin_avatar_00c13032 from 'nuxt_plugin_avatar_00c13032' // Source: ../plugins/avatar (mode: 'all')
import nuxt_plugin_mobileapp_0dc68e62 from 'nuxt_plugin_mobileapp_0dc68e62' // Source: ../plugins/mobile-app (mode: 'all')
import nuxt_plugin_handlewebviewurlloading_8867a90a from 'nuxt_plugin_handlewebviewurlloading_8867a90a' // Source: ../plugins/handle-webview-url-loading.client (mode: 'client')
import nuxt_plugin_megatronclient_175f2760 from 'nuxt_plugin_megatronclient_175f2760' // Source: ../plugins/megatron-client (mode: 'all')
import nuxt_plugin_httpclient_46d31d77 from 'nuxt_plugin_httpclient_46d31d77' // Source: ../plugins/http-client (mode: 'all')
import nuxt_plugin_navigationcontext_7e4a247c from 'nuxt_plugin_navigationcontext_7e4a247c' // Source: ../plugins/navigation-context.server (mode: 'server')
import nuxt_plugin_navigationcontext_b4289b6c from 'nuxt_plugin_navigationcontext_b4289b6c' // Source: ../plugins/navigation-context.client (mode: 'client')
import nuxt_plugin_servicesinject_fcca99b2 from 'nuxt_plugin_servicesinject_fcca99b2' // Source: ../plugins/services-inject (mode: 'all')
import nuxt_plugin_themevariables_102223ba from 'nuxt_plugin_themevariables_102223ba' // Source: ../plugins/theme-variables (mode: 'all')
import nuxt_plugin_validation_5ee4f332 from 'nuxt_plugin_validation_5ee4f332' // Source: ../plugins/validation.client (mode: 'client')
import nuxt_plugin_recentlyviewedproducts_2638c950 from 'nuxt_plugin_recentlyviewedproducts_2638c950' // Source: ../plugins/recently-viewed-products.client (mode: 'client')
import nuxt_plugin_localwishlist_1fa859b9 from 'nuxt_plugin_localwishlist_1fa859b9' // Source: ../plugins/local-wishlist.client (mode: 'client')
import nuxt_plugin_googleauth_60253ad4 from 'nuxt_plugin_googleauth_60253ad4' // Source: ../plugins/google-auth.client (mode: 'client')
import nuxt_plugin_facebook_04e5a93a from 'nuxt_plugin_facebook_04e5a93a' // Source: ../plugins/facebook.client (mode: 'client')
import nuxt_plugin_intersectionobserverpolyfill_5695c31e from 'nuxt_plugin_intersectionobserverpolyfill_5695c31e' // Source: ../plugins/intersection-observer-polyfill.client (mode: 'client')
import nuxt_plugin_arrayatpolyfill_34f4e03d from 'nuxt_plugin_arrayatpolyfill_34f4e03d' // Source: ../plugins/array-at-polyfill.client (mode: 'client')
import nuxt_plugin_pictureelement_a0ff0ef6 from 'nuxt_plugin_pictureelement_a0ff0ef6' // Source: ../plugins/picture-element.client (mode: 'client')
import nuxt_plugin_recaptchav3_2bf5c0bd from 'nuxt_plugin_recaptchav3_2bf5c0bd' // Source: ../plugins/recaptcha-v3.client (mode: 'client')
import nuxt_plugin_graphqlerrorshelper_a7252d1a from 'nuxt_plugin_graphqlerrorshelper_a7252d1a' // Source: ../plugins/graphql-errors-helper (mode: 'all')
import nuxt_plugin_displaymodewatcher_c276fa3a from 'nuxt_plugin_displaymodewatcher_c276fa3a' // Source: ../plugins/display-mode-watcher.client (mode: 'client')
import nuxt_plugin_customeventpolyfill_3b105ed4 from 'nuxt_plugin_customeventpolyfill_3b105ed4' // Source: ../plugins/custom-event-polyfill.client (mode: 'client')
import nuxt_plugin_replacechildrenpolyfill_b69e13e4 from 'nuxt_plugin_replacechildrenpolyfill_b69e13e4' // Source: ../plugins/replace-children-polyfill.client (mode: 'client')
import nuxt_plugin_ismobilewatcher_064822fe from 'nuxt_plugin_ismobilewatcher_064822fe' // Source: ../plugins/is-mobile-watcher.client (mode: 'client')
import nuxt_plugin_istabletordesktopwatcher_7032eeca from 'nuxt_plugin_istabletordesktopwatcher_7032eeca' // Source: ../plugins/is-tablet-or-desktop-watcher.client (mode: 'client')
import nuxt_plugin_isdesktopwatcher_1c183298 from 'nuxt_plugin_isdesktopwatcher_1c183298' // Source: ../plugins/is-desktop-watcher.client (mode: 'client')
import nuxt_plugin_pwaeventshandler_522d3457 from 'nuxt_plugin_pwaeventshandler_522d3457' // Source: ../plugins/pwa-events-handler.client (mode: 'client')
import nuxt_plugin_webpushnotificationsrequestpermission_56a59a26 from 'nuxt_plugin_webpushnotificationsrequestpermission_56a59a26' // Source: ../plugins/web-push-notifications-request-permission.client (mode: 'client')
import nuxt_plugin_nuxtclientinit_3898ce90 from 'nuxt_plugin_nuxtclientinit_3898ce90' // Source: ../plugins/nuxt-client-init.client (mode: 'client')
import nuxt_plugin_fetchinparallel_db2722ba from 'nuxt_plugin_fetchinparallel_db2722ba' // Source: ../plugins/fetch-in-parallel (mode: 'all')
import nuxt_plugin_vuetouchevents_6562a7b2 from 'nuxt_plugin_vuetouchevents_6562a7b2' // Source: ../plugins/vue-touch-events.client (mode: 'client')
import nuxt_plugin_storetiming_6cbee342 from 'nuxt_plugin_storetiming_6cbee342' // Source: ../plugins/store-timing.server (mode: 'server')
import nuxt_plugin_loyaltyclub_05e0e5f6 from 'nuxt_plugin_loyaltyclub_05e0e5f6' // Source: ../plugins/loyalty-club (mode: 'all')
import nuxt_plugin_register_66bbec80 from 'nuxt_plugin_register_66bbec80' // Source: ../modules/rma/src/async-services/register (mode: 'all')
import nuxt_plugin_register_704f4011 from 'nuxt_plugin_register_704f4011' // Source: ../modules/rma/src/i18n/register (mode: 'all')
import nuxt_plugin_plugin_77386af4 from 'nuxt_plugin_plugin_77386af4' // Source: ../modules/rma/src/errors/plugin (mode: 'all')
import nuxt_plugin_register_11da42e0 from 'nuxt_plugin_register_11da42e0' // Source: ../modules/search/src/async-services/register (mode: 'all')
import nuxt_plugin_register_da57e31e from 'nuxt_plugin_register_da57e31e' // Source: ../modules/search/src/i18n/register (mode: 'all')
import nuxt_plugin_register_b96d4a8c from 'nuxt_plugin_register_b96d4a8c' // Source: ../modules/product/src/i18n/register (mode: 'all')
import nuxt_plugin_register_62bfff40 from 'nuxt_plugin_register_62bfff40' // Source: ../modules/merchant/src/async-services/register (mode: 'all')
import nuxt_plugin_register_37f07a5e from 'nuxt_plugin_register_37f07a5e' // Source: ../modules/merchant/src/i18n/register (mode: 'all')
import nuxt_plugin_plugin_2a1e2498 from 'nuxt_plugin_plugin_2a1e2498' // Source: ../modules/merchant/src/errors/plugin (mode: 'all')
import nuxt_plugin_markpluginsend_ed98031a from 'nuxt_plugin_markpluginsend_ed98031a' // Source: ../plugins/performance/mark-plugins-end.client (mode: 'client')
import nuxt_plugin_pluginstimingend_17ccf514 from 'nuxt_plugin_pluginstimingend_17ccf514' // Source: ../plugins/performance/plugins-timing-end.server (mode: 'server')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, shrink-to-fit=no"},{"hid":"referrer","name":"referrer","content":"no-referrer-when-downgrade"},{"name":"p:domain_verify","content":"de76152733d18096386995b9e42ebe13"},{"hid":"mobile-web-app-capable","name":"mobile-web-app-capable","content":"yes"},{"hid":"og:locale","property":"og:locale","content":"pl_PL"},{"hid":"og:type","property":"og:type","content":"website"}],"link":[],"style":[],"script":[{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function(w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);}})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":""}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_pluginstimingstart_5c03b7a6 === 'function') {
    await nuxt_plugin_pluginstimingstart_5c03b7a6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_markpluginsstart_f2315868 === 'function') {
    await nuxt_plugin_markpluginsstart_f2315868(app.context, inject)
  }

  if (typeof nuxt_plugin_abtests_5a7f2e0e === 'function') {
    await nuxt_plugin_abtests_5a7f2e0e(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_x3configprovider_438a352e === 'function') {
    await nuxt_plugin_x3configprovider_438a352e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_x3configprovider_289af9b6 === 'function') {
    await nuxt_plugin_x3configprovider_289af9b6(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_394f2b2c === 'function') {
    await nuxt_plugin_sentryserver_394f2b2c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_7d9ef8e2 === 'function') {
    await nuxt_plugin_sentryclient_7d9ef8e2(app.context, inject)
  }

  if (typeof nuxt_plugin_staticfoldershashesplugin32157be9_02e676aa === 'function') {
    await nuxt_plugin_staticfoldershashesplugin32157be9_02e676aa(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginspathhelperse09eeb70_36fb8c0c === 'function') {
    await nuxt_plugin_pluginspathhelperse09eeb70_36fb8c0c(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtcorrelation_4c450d3a === 'function') {
    await nuxt_plugin_nuxtcorrelation_4c450d3a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nuxtanalyticsclient_76f438ff === 'function') {
    await nuxt_plugin_nuxtanalyticsclient_76f438ff(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtmodals_3eaf3dac === 'function') {
    await nuxt_plugin_nuxtmodals_3eaf3dac(app.context, inject)
  }

  if (typeof nuxt_plugin_filterhandler_3da7fe9d === 'function') {
    await nuxt_plugin_filterhandler_3da7fe9d(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtrumspeedcurveplugin_2e871c87 === 'function') {
    await nuxt_plugin_nuxtrumspeedcurveplugin_2e871c87(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtuseragent_08e203ef === 'function') {
    await nuxt_plugin_nuxtuseragent_08e203ef(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_35496ea3 === 'function') {
    await nuxt_plugin_vuescrollto_35496ea3(app.context, inject)
  }

  if (typeof nuxt_plugin_smoothscrollpolyfill_d5811bde === 'function') {
    await nuxt_plugin_smoothscrollpolyfill_d5811bde(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_srcpluginclient07628a4c_22d37ea5 === 'function') {
    await nuxt_plugin_srcpluginclient07628a4c_22d37ea5(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_srcpluginserver6d1bfbd4_5011be26 === 'function') {
    await nuxt_plugin_srcpluginserver6d1bfbd4_5011be26(app.context, inject)
  }

  if (typeof nuxt_plugin_srcplugin30da31e0_8a2b7d30 === 'function') {
    await nuxt_plugin_srcplugin30da31e0_8a2b7d30(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_templatesworkboxd1e23392_cae43754 === 'function') {
    await nuxt_plugin_templatesworkboxd1e23392_cae43754(app.context, inject)
  }

  if (typeof nuxt_plugin_srcplugin53f2c960_6405a85e === 'function') {
    await nuxt_plugin_srcplugin53f2c960_6405a85e(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_25512e08 === 'function') {
    await nuxt_plugin_gtm_25512e08(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_uuid_7bbe03b6 === 'function') {
    await nuxt_plugin_uuid_7bbe03b6(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_googleconsentmodedefault_31a576e8 === 'function') {
    await nuxt_plugin_googleconsentmodedefault_31a576e8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gtminit_0fc08a83 === 'function') {
    await nuxt_plugin_gtminit_0fc08a83(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_rumspeedcurve_bf3bd054 === 'function') {
    await nuxt_plugin_rumspeedcurve_bf3bd054(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_marknuxtready_7d47d618 === 'function') {
    await nuxt_plugin_marknuxtready_7d47d618(app.context, inject)
  }

  if (typeof nuxt_plugin_performancerecorder_77bf73d3 === 'function') {
    await nuxt_plugin_performancerecorder_77bf73d3(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pagereferrer_75a31f56 === 'function') {
    await nuxt_plugin_pagereferrer_75a31f56(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_beforeinstallpromptlistener_ce019842 === 'function') {
    await nuxt_plugin_beforeinstallpromptlistener_ce019842(app.context, inject)
  }

  if (typeof nuxt_plugin_errorhandler_11af5f8c === 'function') {
    await nuxt_plugin_errorhandler_11af5f8c(app.context, inject)
  }

  if (typeof nuxt_plugin_pathhelper_d4b9f9c6 === 'function') {
    await nuxt_plugin_pathhelper_d4b9f9c6(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_d9e26992 === 'function') {
    await nuxt_plugin_i18n_d9e26992(app.context, inject)
  }

  if (typeof nuxt_plugin_imaginator_2938a71e === 'function') {
    await nuxt_plugin_imaginator_2938a71e(app.context, inject)
  }

  if (typeof nuxt_plugin_datehelper_63471b74 === 'function') {
    await nuxt_plugin_datehelper_63471b74(app.context, inject)
  }

  if (typeof nuxt_plugin_cookies_7600aed6 === 'function') {
    await nuxt_plugin_cookies_7600aed6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_storage_e473a712 === 'function') {
    await nuxt_plugin_storage_e473a712(app.context, inject)
  }

  if (typeof nuxt_plugin_avatar_00c13032 === 'function') {
    await nuxt_plugin_avatar_00c13032(app.context, inject)
  }

  if (typeof nuxt_plugin_mobileapp_0dc68e62 === 'function') {
    await nuxt_plugin_mobileapp_0dc68e62(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_handlewebviewurlloading_8867a90a === 'function') {
    await nuxt_plugin_handlewebviewurlloading_8867a90a(app.context, inject)
  }

  if (typeof nuxt_plugin_megatronclient_175f2760 === 'function') {
    await nuxt_plugin_megatronclient_175f2760(app.context, inject)
  }

  if (typeof nuxt_plugin_httpclient_46d31d77 === 'function') {
    await nuxt_plugin_httpclient_46d31d77(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_navigationcontext_7e4a247c === 'function') {
    await nuxt_plugin_navigationcontext_7e4a247c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_navigationcontext_b4289b6c === 'function') {
    await nuxt_plugin_navigationcontext_b4289b6c(app.context, inject)
  }

  if (typeof nuxt_plugin_servicesinject_fcca99b2 === 'function') {
    await nuxt_plugin_servicesinject_fcca99b2(app.context, inject)
  }

  if (typeof nuxt_plugin_themevariables_102223ba === 'function') {
    await nuxt_plugin_themevariables_102223ba(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_validation_5ee4f332 === 'function') {
    await nuxt_plugin_validation_5ee4f332(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_recentlyviewedproducts_2638c950 === 'function') {
    await nuxt_plugin_recentlyviewedproducts_2638c950(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_localwishlist_1fa859b9 === 'function') {
    await nuxt_plugin_localwishlist_1fa859b9(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googleauth_60253ad4 === 'function') {
    await nuxt_plugin_googleauth_60253ad4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_facebook_04e5a93a === 'function') {
    await nuxt_plugin_facebook_04e5a93a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_intersectionobserverpolyfill_5695c31e === 'function') {
    await nuxt_plugin_intersectionobserverpolyfill_5695c31e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_arrayatpolyfill_34f4e03d === 'function') {
    await nuxt_plugin_arrayatpolyfill_34f4e03d(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pictureelement_a0ff0ef6 === 'function') {
    await nuxt_plugin_pictureelement_a0ff0ef6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_recaptchav3_2bf5c0bd === 'function') {
    await nuxt_plugin_recaptchav3_2bf5c0bd(app.context, inject)
  }

  if (typeof nuxt_plugin_graphqlerrorshelper_a7252d1a === 'function') {
    await nuxt_plugin_graphqlerrorshelper_a7252d1a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_displaymodewatcher_c276fa3a === 'function') {
    await nuxt_plugin_displaymodewatcher_c276fa3a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_customeventpolyfill_3b105ed4 === 'function') {
    await nuxt_plugin_customeventpolyfill_3b105ed4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_replacechildrenpolyfill_b69e13e4 === 'function') {
    await nuxt_plugin_replacechildrenpolyfill_b69e13e4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_ismobilewatcher_064822fe === 'function') {
    await nuxt_plugin_ismobilewatcher_064822fe(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_istabletordesktopwatcher_7032eeca === 'function') {
    await nuxt_plugin_istabletordesktopwatcher_7032eeca(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_isdesktopwatcher_1c183298 === 'function') {
    await nuxt_plugin_isdesktopwatcher_1c183298(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pwaeventshandler_522d3457 === 'function') {
    await nuxt_plugin_pwaeventshandler_522d3457(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_webpushnotificationsrequestpermission_56a59a26 === 'function') {
    await nuxt_plugin_webpushnotificationsrequestpermission_56a59a26(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nuxtclientinit_3898ce90 === 'function') {
    await nuxt_plugin_nuxtclientinit_3898ce90(app.context, inject)
  }

  if (typeof nuxt_plugin_fetchinparallel_db2722ba === 'function') {
    await nuxt_plugin_fetchinparallel_db2722ba(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuetouchevents_6562a7b2 === 'function') {
    await nuxt_plugin_vuetouchevents_6562a7b2(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_storetiming_6cbee342 === 'function') {
    await nuxt_plugin_storetiming_6cbee342(app.context, inject)
  }

  if (typeof nuxt_plugin_loyaltyclub_05e0e5f6 === 'function') {
    await nuxt_plugin_loyaltyclub_05e0e5f6(app.context, inject)
  }

  if (typeof nuxt_plugin_register_66bbec80 === 'function') {
    await nuxt_plugin_register_66bbec80(app.context, inject)
  }

  if (typeof nuxt_plugin_register_704f4011 === 'function') {
    await nuxt_plugin_register_704f4011(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_77386af4 === 'function') {
    await nuxt_plugin_plugin_77386af4(app.context, inject)
  }

  if (typeof nuxt_plugin_register_11da42e0 === 'function') {
    await nuxt_plugin_register_11da42e0(app.context, inject)
  }

  if (typeof nuxt_plugin_register_da57e31e === 'function') {
    await nuxt_plugin_register_da57e31e(app.context, inject)
  }

  if (typeof nuxt_plugin_register_b96d4a8c === 'function') {
    await nuxt_plugin_register_b96d4a8c(app.context, inject)
  }

  if (typeof nuxt_plugin_register_62bfff40 === 'function') {
    await nuxt_plugin_register_62bfff40(app.context, inject)
  }

  if (typeof nuxt_plugin_register_37f07a5e === 'function') {
    await nuxt_plugin_register_37f07a5e(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_2a1e2498 === 'function') {
    await nuxt_plugin_plugin_2a1e2498(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_markpluginsend_ed98031a === 'function') {
    await nuxt_plugin_markpluginsend_ed98031a(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_pluginstimingend_17ccf514 === 'function') {
    await nuxt_plugin_pluginstimingend_17ccf514(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
