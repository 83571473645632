export const CMS_COMPONENTS_PREFIX = 'Cms';

export const CMS_BASE_PARAGRAPH = 'CmsBaseParagraph';
export const CMS_TEXT = 'CmsText';
export const CMS_BASE_HEADING = 'CmsBaseHeading';
export const CMS_BASE_LINK = 'CmsBaseLink';
export const CMS_CONTAINER_CONTENT = 'CmsContainerContent';
export const CMS_CONTAINER_CONTENT_WITH_SIDEBAR = 'CmsContainerContentWithSidebar';
export const CMS_BASE_LABEL = 'CmsBaseLabel';
export const CMS_BASE_BUTTON = 'CmsBaseButton';
export const CMS_BASE_TAG = 'CmsBaseTag';
export const CMS_PRODUCT_CARD = 'CmsProductCard';
export const CMS_BASE_BADGE = 'CmsBaseBadge';
export const CMS_LIST = 'CmsList';
export const CMS_LIST_ITEM = 'CmsListItem';
export const CMS_BLOCK_IMPORT = 'CmsBlockImport';
export const CMS_PAGE = 'CmsPage';
export const CMS_LIVE_STREAM_ACCORDION = 'CmsLiveStreamAccordion';
export const CMS_LIVE_STREAM_ACCORDION_ITEM = 'CmsLiveStreamAccordionItem';
export const CMS_MARKETING_IMAGE_TILES_SIMPLE_SLIDER = 'CmsMarketingImageTilesSimpleSlider';
export const CMS_MARKETING_IMAGE_TILES_SIMPLE_SLIDER_SLIDE =
    'CmsMarketingImageTilesSimpleSliderSlide';
export const CMS_MARKETPLACE_FAMILIAR_BRANDS = 'CmsMarketplaceFamiliarBrands';
export const CMS_MARKETPLACE_FAMILIAR_BRAND = 'CmsMarketplaceFamiliarBrand';
export const CMS_MARKETING_DISCOUNT_BANNER = 'CmsMarketingDiscountBanner';
export const CMS_MARKETING_BANNER = 'CmsMarketingBanner';
export const CMS_MARKETING_HERO_BLOCK = 'CmsMarketingHeroBlock';
export const CMS_MARKETING_HERO_BLOCK_IMAGE = 'CmsMarketingHeroBlockImage';
export const CMS_MARKETING_HERO_BLOCK_VIDEO = 'CmsMarketingHeroBlockVideo';
export const CMS_RECOMMENDED_PRODUCTS_SIMPLE_SLIDER_WRAPPER =
    'CmsRecommendedProductsSimpleSliderWrapper';
export const CMS_RECENTLY_VIEWED_PRODUCTS_SIMPLE_SLIDER_WRAPPER =
    'CmsRecentlyViewedProductsSimpleSliderWrapper';
export const CMS_MARKETING_CARDS_SLIDER = 'CmsMarketingCardsSlider';
export const CMS_MARKETING_CARDS_SLIDER_SLIDE = 'CmsMarketingCardsSliderSlide';
export const CMS_MARKETING_CARDS_SLIDER2 = 'CmsMarketingCardsSlider2';
export const CMS_MARKETING_CARDS_SLIDER2_SLIDE = 'CmsMarketingCardsSlider2Slide';
export const CMS_MARKETING_BRANDS_SLIDER = 'CmsMarketingBrandsSlider';
export const CMS_MARKETING_BRANDS_SLIDER_SLIDE = 'CmsMarketingBrandsSliderSlide';
export const CMS_MARKETING_BLOG = 'CmsMarketingBlog';
export const CMS_MARKETING_CELEBRITY_SLIDER_SLIDE = 'CmsMarketingCelebritySliderSlide';
export const CMS_MARKETING_CELEBRITY_SLIDER = 'CmsMarketingCelebritySlider';
export const CMS_MARKETING_CELEBRITY_SLIDER_SLIDE_NEW = 'CmsMarketingCelebritySliderSlideNew';
export const CMS_MARKETING_CELEBRITY_SLIDER_NEW = 'CmsMarketingCelebritySliderNew';
export const CMS_MARKETING_INFLUENCER_SLIDER = 'CmsMarketingInfluencerSlider';
export const CMS_MARKETING_INFLUENCER_SLIDER_SLIDE = 'CmsMarketingInfluencerSliderSlide';
export const CMS_MARKETING_POPULAR_CATEGORIES = 'CmsMarketingPopularCategories';
export const CMS_MARKETING_POPULAR_CATEGORY = 'CmsMarketingPopularCategory';
export const CMS_LOYALTY_CLUB_DARK_SECTION = 'CmsLoyaltyClubDarkSection';
export const CMS_LOYALTY_CLUB_AMBASSADORS_SLIDER = 'CmsLoyaltyClubAmbassadorsSlider';
export const CMS_LOYALTY_CLUB_AMBASSADORS_SLIDER_SLIDE = 'CmsLoyaltyClubAmbassadorsSliderSlide';
export const CMS_MARKETING_MULTI_LINKS_BANNER_SMALL = 'CmsMarketingMultiLinkBannerSmall';
export const CMS_MARKETING_MULTI_LINKS_BANNER_LARGE = 'CmsMarketingMultiLinkBannerLarge';
export const CMS_MARKETING_MULTI_LINKS_BANNER_LINK = 'CmsMarketingMultiLinkBannerLink';
export const CMS_MARKETING_HOMEPAGE_SECTION = 'CmsMarketingHomepageSection';
export const CMS_MARKETING_HOMEPAGE_SECTION_CATEGORY = 'CmsMarketingHomepageSectionCategory';
export const CMS_MARKETING_CATEGORY_SLIDER = 'CmsMarketingCategorySlider';
export const CMS_MARKETING_USP_BENEFITS = 'CmsMarketingUSPBenefits';
export const CMS_MARKETING_USP_BENEFIT = 'CmsMarketingUSPBenefit';
export const CMS_MARKETING_MAIN_CATEGORY_TEXT = 'CmsMarketingMainCategoryText';
export const CMS_MARKETING_MFC_HERO_BANNER = 'CmsMarketingMFCHeroBanner';
export const CMS_MARKETING_VIDEO_BANNER = 'CmsMarketingVideoBanner';
export const CMS_MARKETING_SALE_BAR = 'CmsMarketingSaleBar';
export const CMS_MARKETING_HERO = 'CmsMarketingHero';
export const CMS_MARKETING_HERO_BANNER = 'CmsMarketingHeroBanner';
export const CMS_MARKETING_MULTI_BANNER = 'CmsMarketingMultiBanner';
export const CMS_MARKETING_MARKETING_BLOG_BAR = 'CmsMarketingBlogBar';
export const CMS_MARKETING_SLIDER_SECTION = 'CmsMarketingSliderSection';
export const CMS_MARKETING_PROMO_CATEGORIES = 'CmsMarketingPromoCategories';
export const CMS_MARKETING_PROMO_CATEGORIES_SLIDER = 'CmsMarketingPromoCategoriesSlider';
export const CMS_MARKETING_PROMO_CATEGORIES_SLIDER_ITEM = 'CmsMarketingPromoCategoriesSliderItem';
export const CMS_MARKETPLACE_STEPS = 'CmsMarketplaceSteps';
export const CMS_MARKETPLACE_COOPERATE_WITH_US = 'CmsMarketplaceCooperateWithUs';
export const CMS_MARKETPLACE_COOPERATE_WITH_US_ITEM = 'CmsMarketplaceCooperateWithUsItem';
export const CMS_MARKETPLACE_PARTNERS_BENEFITS = 'CmsMarketplacePartnersBenefits';
export const CMS_MARKETPLACE_PARTNERS_BENEFIT = 'CmsMarketplacePartnersBenefit';
export const CMS_FAQ_LIST = 'CmsFAQList';
export const CMS_FAQ_LIST_ITEM = 'CmsFAQListItem';
export const CMS_MARKETPLACE_COOPERATION_WITH_MODIVO = 'CmsMarketplaceCooperationWithModivo';
export const CMS_GLOBAL_CONFIG = 'CmsGlobalConfig';
export const CMS_SMART_BANNER_CONFIG = 'CmsSmartBannerConfig';
export const CMS_HELP_CENTER_CONFIG = 'CmsHelpCenterConfig';
export const CMS_STATIC_BLOCK = 'CmsStaticBlock';
export const CMS_PRODUCT_ACCORDION = 'CmsProductAccordion';
export const CMS_FOOTER_LINKS = 'CmsFooterLinks';
export const CMS_FOOTER_LINK = 'CmsFooterLink';
export const CMS_FOOTER_LINK_BOTTOM = 'CmsFooterLinkBottom';
export const CMS_FOOTER_CONFIGURATION = 'CmsFooterConfiguration';
export const CMS_BASE_FOOTER_LINKS = 'CmsBaseFooterLinks';
export const CMS_MARKETING_PRODUCTS_SLIDER = 'CmsMarketingProductsSlider';
export const CMS_MARKETING_PROMO_SLIDER = 'CmsMarketingPromoSlider';
export const CMS_PROMO_ACTION_REDIRECT = 'CmsPromoActionRedirect';
export const CMS_GOOGLE_ANALYTICS_CONFIG = 'CmsGoogleAnalyticsConfig';
export const CMS_PAGE_WITH_ACCORDIONS = 'CmsStaticBlockWithAccordions';
export const CMS_MARKETING_SIMPLE_PICTURE_BOX = 'CmsMarketingSimplePictureBox';
export const CMS_MARKETING_SIMPLE_PICTURE_BOX_GRID = 'CmsMarketingSimplePictureBoxGrid';
export const CMS_ZOWIE_FORM = 'CmsZowieForm';
export const CMS_BRAND_SHOP_LIST = 'CmsBrandShopList';
export const CMS_BRAND_SHOP_ITEM = 'CmsBrandShopItem';
export const CMS_SPONSORED_SLIDER_WRAPPER = 'CmsSponsoredSliderWrapper';
export const CMS_TEST_NEW_TYPES = 'CmsTestNewTypes';
export const CMS_SYNERISE_MAP_CATEGORIES = 'CmsSyneriseMapCategories';
export const CMS_CUSTOM_PRODUCTS_SIMPLE_SLIDER_WRAPPER = 'CmsCustomProductsSimpleSliderWrapper';
export const CMS_APP = 'CmsApp';
export const CMS_APP_COUNTER_V2 = 'CmsAppCounterV2';
export const CMS_APP_HEADER_V2 = 'CmsAppHeaderV2';
export const CMS_APP_HEADER_INFO_V2 = 'CmsAppHeaderInfoV2';
export const CMS_APP_SOCIAL_MEDIA_V2 = 'CmsAppSocialMediaV2';
export const CMS_APP_BANNER_V2 = 'CmsAppBannerV2';
export const CMS_APP_LARGE_CAROUSEL_V2 = 'CmsAppLargeCarouselV2';
export const CMS_APP_SMALL_CAROUSEL_V2 = 'CmsAppSmallCarouselV2';
export const CMS_APP_MENU_LIST_ITEM_V2 = 'CmsAppMenuListItemV2';
export const CMS_APP_MENU_LIST_V2 = 'CmsAppMenuListV2';
export const CMS_APP_BENEFIT_LIST_ITEM_V2 = 'CmsAppBenefitListItemV2';
export const CMS_APP_BENEFIT_LIST_V2 = 'CmsAppBenefitListV2';
export const CMS_APP_IMAGE_GRID_ITEM_V2 = 'CmsAppImageGridItemV2';
export const CMS_APP_IMAGE_GRID_ITEM_TEXT_V2 = 'CmsAppImageGridItemTextV2';
export const CMS_APP_IMAGE_CAROUSEL_ITEM_V2 = 'CmsAppImageCarouselItemV2';
export const CMS_APP_MAIN_CATEGORY_SWITCHER_LIST_ITEM_V2 = 'CmsAppMainCategorySwitcherListItemV2';
export const CMS_APP_MAIN_CATEGORY_SWITCHER_LIST_V2 = 'CmsAppMainCategorySwitcherListV2';
export const CMS_APP_SMALL_IMAGE_GRID_V2 = 'CmsAppSmallImageGridV2';
export const CMS_APP_LARGE_IMAGE_GRID_V2 = 'CmsAppLargeImageGridV2';
export const CMS_APP_IMAGE_CAROUSEL_V2 = 'CmsAppImageCarouselV2';
export const CMS_APP_BANNER_WITH_SMALL_CAROUSEL_V2 = 'CmsAppBannerWithSmallCarouselV2';
export const CMS_APP_MARKETING_BAR_V2 = 'CmsAppMarketingBarV2';
export const CMS_APP_MESSAGE_ICON_BANNER_V2 = 'CmsAppMessageIconBannerV2';
export const CMS_APP_PRODUCT_SCREEN_V2 = 'CmsAppProductScreenV2';
export const CMS_APP_PRODUCT_LISTING_SCREEN_V2 = 'CmsAppProductListingScreenV2';
export const CMS_APP_BANNER_WITH_LARGE_CAROUSEL_V2 = 'CmsAppBannerWithLargeCarouselV2';
export const CMS_APP_HOME_SCREEN_V2 = 'CmsAppHomeScreenV2';
export const CMS_APP_PAGE_SCREEN_V2 = 'CmsAppPageScreenV2';
export const CMS_APP_SEARCH_MENU_SCREEN_V2 = 'CmsAppSearchMenuScreenV2';
export const CMS_APP_VISIBLE_RULES_V2 = 'CmsAppVisibleRulesV2';
export const CMS_APP_SEARCH_SCREEN_V2 = 'CmsAppSearchScreenV2';
export const CMS_APP_CART_SCREEN_V2 = 'CmsAppCartScreenV2';
export const CMS_APP_FAVOURITES_SCREEN_V2 = 'CmsAppFavouritesScreenV2';
export const CMS_MARKETING_BAR_LINK = 'CmsMarketingBarLink';
export const CMS_MARKETING_BAR_DEFAULT = 'CmsMarketingBarDefault';
export const CMS_MARKETING_BAR_DISCOUNT_CODE = 'CmsMarketingBarDiscountCode';
export const CMS_MARKETING_BAR_TEXT_AND_LINKS = 'CmsMarketingBarTextAndLinks';
export const CMS_MARKETING_BAR_TIMER = 'CmsMarketingBarTimer';
export const CMS_PAYMENT_WIDGET_CONFIG = 'CmsPaymentWidgetConfig';
export const CMS_SYNERISE_CONFIG = 'CmsSyneriseConfig';
export const CMS_LISTING_SURVEY_CONFIG = 'CmsListingSurveyConfig';

export const COMPONENT_ALLOWED_CHILDREN = {
    [CMS_CONTAINER_CONTENT]: [
        CMS_BASE_PARAGRAPH,
        CMS_BASE_HEADING,
        CMS_BASE_LINK,
        CMS_TEXT,
        CMS_BASE_LABEL,
        CMS_BASE_BUTTON,
        CMS_BASE_TAG,
        CMS_PRODUCT_CARD,
        CMS_BASE_BADGE,
        CMS_LIST,
        CMS_BLOCK_IMPORT,
        CMS_LIVE_STREAM_ACCORDION,
        CMS_STATIC_BLOCK,
        CMS_ZOWIE_FORM,
        CMS_MARKETING_PROMO_CATEGORIES_SLIDER,
    ],
    [CMS_CONTAINER_CONTENT_WITH_SIDEBAR]: [
        CMS_BASE_PARAGRAPH,
        CMS_BASE_HEADING,
        CMS_BASE_LINK,
        CMS_TEXT,
        CMS_BASE_LABEL,
        CMS_BASE_BUTTON,
        CMS_BASE_TAG,
        CMS_PRODUCT_CARD,
        CMS_BASE_BADGE,
        CMS_LIST,
        CMS_BLOCK_IMPORT,
        CMS_LIVE_STREAM_ACCORDION,
        CMS_STATIC_BLOCK,
        CMS_ZOWIE_FORM,
    ],
    [CMS_BASE_PARAGRAPH]: [CMS_TEXT, CMS_BASE_LINK, CMS_BASE_LABEL, CMS_BASE_BADGE],
    [CMS_LIST]: [CMS_LIST_ITEM],
    [CMS_LIST_ITEM]: [CMS_TEXT, CMS_BASE_LINK],
    [CMS_LIVE_STREAM_ACCORDION]: [CMS_LIVE_STREAM_ACCORDION_ITEM],
    [CMS_LIVE_STREAM_ACCORDION_ITEM]: [CMS_MARKETING_CELEBRITY_SLIDER],
    [CMS_PAGE]: [
        CMS_MARKETING_USP_BENEFITS,
        CMS_CONTAINER_CONTENT,
        CMS_CONTAINER_CONTENT_WITH_SIDEBAR,
        CMS_MARKETING_BRANDS_SLIDER,
        CMS_MARKETING_DISCOUNT_BANNER,
        CMS_MARKETING_BANNER,
        CMS_MARKETING_HERO_BLOCK,
        CMS_MARKETING_MULTI_BANNER,
        CMS_MARKETING_BLOG,
        CMS_RECOMMENDED_PRODUCTS_SIMPLE_SLIDER_WRAPPER,
        CMS_RECENTLY_VIEWED_PRODUCTS_SIMPLE_SLIDER_WRAPPER,
        CMS_MARKETING_CARDS_SLIDER,
        CMS_MARKETING_CARDS_SLIDER2,
        CMS_MARKETING_CELEBRITY_SLIDER,
        CMS_MARKETING_INFLUENCER_SLIDER,
        CMS_MARKETING_POPULAR_CATEGORIES,
        CMS_LOYALTY_CLUB_DARK_SECTION,
        CMS_LOYALTY_CLUB_AMBASSADORS_SLIDER,
        CMS_MARKETING_MULTI_LINKS_BANNER_SMALL,
        CMS_MARKETING_MULTI_LINKS_BANNER_LARGE,
        CMS_MARKETING_HOMEPAGE_SECTION,
        CMS_MARKETING_CATEGORY_SLIDER,
        CMS_MARKETING_CELEBRITY_SLIDER_NEW,
        CMS_MARKETING_MFC_HERO_BANNER,
        CMS_MARKETING_VIDEO_BANNER,
        CMS_MARKETING_IMAGE_TILES_SIMPLE_SLIDER,
        CMS_MARKETPLACE_FAMILIAR_BRANDS,
        CMS_MARKETING_SALE_BAR,
        CMS_MARKETING_MAIN_CATEGORY_TEXT,
        CMS_MARKETING_HERO_BANNER,
        CMS_MARKETING_MARKETING_BLOG_BAR,
        CMS_MARKETPLACE_STEPS,
        CMS_MARKETPLACE_COOPERATE_WITH_US,
        CMS_MARKETPLACE_PARTNERS_BENEFITS,
        CMS_FAQ_LIST,
        CMS_MARKETPLACE_COOPERATION_WITH_MODIVO,
        CMS_GLOBAL_CONFIG,
        CMS_HELP_CENTER_CONFIG,
        CMS_STATIC_BLOCK,
        CMS_FOOTER_LINKS,
        CMS_FOOTER_LINK,
        CMS_FOOTER_CONFIGURATION,
        CMS_MARKETING_PROMO_SLIDER,
        CMS_PROMO_ACTION_REDIRECT,
        CMS_GOOGLE_ANALYTICS_CONFIG,
        CMS_PAGE_WITH_ACCORDIONS,
        CMS_MARKETING_SIMPLE_PICTURE_BOX_GRID,
        CMS_ZOWIE_FORM,
        CMS_BRAND_SHOP_LIST,
        CMS_SPONSORED_SLIDER_WRAPPER,
        CMS_TEST_NEW_TYPES,
        CMS_SMART_BANNER_CONFIG,
        CMS_SYNERISE_MAP_CATEGORIES,
        CMS_APP,
        CMS_MARKETING_BAR_DEFAULT,
        CMS_MARKETING_BAR_DISCOUNT_CODE,
        CMS_MARKETING_BAR_TEXT_AND_LINKS,
        CMS_MARKETING_BAR_TIMER,
        CMS_PAYMENT_WIDGET_CONFIG,
        CMS_SYNERISE_CONFIG,
        CMS_LISTING_SURVEY_CONFIG,
        CMS_MARKETING_SLIDER_SECTION,
    ],
    [CMS_MARKETING_INFLUENCER_SLIDER]: [CMS_MARKETING_INFLUENCER_SLIDER_SLIDE],
    [CMS_MARKETING_CARDS_SLIDER]: [CMS_MARKETING_CARDS_SLIDER_SLIDE],
    [CMS_MARKETING_CARDS_SLIDER2]: [CMS_MARKETING_CARDS_SLIDER2_SLIDE],
    [CMS_MARKETING_BRANDS_SLIDER]: [CMS_MARKETING_BRANDS_SLIDER_SLIDE],
    [CMS_MARKETING_CELEBRITY_SLIDER]: [CMS_MARKETING_CELEBRITY_SLIDER_SLIDE],
    [CMS_MARKETING_POPULAR_CATEGORIES]: [CMS_MARKETING_POPULAR_CATEGORY],
    [CMS_LOYALTY_CLUB_AMBASSADORS_SLIDER]: [CMS_LOYALTY_CLUB_AMBASSADORS_SLIDER_SLIDE],
    [CMS_MARKETING_MULTI_LINKS_BANNER_SMALL]: [CMS_TEXT, CMS_MARKETING_MULTI_LINKS_BANNER_LINK],
    [CMS_MARKETING_MULTI_LINKS_BANNER_LARGE]: [CMS_MARKETING_MULTI_LINKS_BANNER_LINK],
    [CMS_MARKETING_HOMEPAGE_SECTION]: [CMS_MARKETING_HOMEPAGE_SECTION_CATEGORY],
    [CMS_MARKETING_CELEBRITY_SLIDER_NEW]: [CMS_MARKETING_CELEBRITY_SLIDER_SLIDE_NEW],
    [CMS_MARKETING_IMAGE_TILES_SIMPLE_SLIDER]: [CMS_MARKETING_IMAGE_TILES_SIMPLE_SLIDER_SLIDE],

    [CMS_MARKETPLACE_FAMILIAR_BRANDS]: [CMS_MARKETPLACE_FAMILIAR_BRAND],
    [CMS_MARKETING_HERO_BANNER]: [CMS_MARKETING_HERO],
    [CMS_MARKETING_HERO_BLOCK]: [CMS_MARKETING_HERO_BLOCK_IMAGE, CMS_MARKETING_HERO_BLOCK_VIDEO],
    [CMS_MARKETING_MULTI_BANNER]: [
        CMS_RECOMMENDED_PRODUCTS_SIMPLE_SLIDER_WRAPPER,
        CMS_CUSTOM_PRODUCTS_SIMPLE_SLIDER_WRAPPER,
    ],
    [CMS_MARKETPLACE_STEPS]: [CMS_TEXT],
    [CMS_MARKETPLACE_COOPERATE_WITH_US]: [CMS_MARKETPLACE_COOPERATE_WITH_US_ITEM],
    [CMS_MARKETPLACE_PARTNERS_BENEFITS]: [CMS_MARKETPLACE_PARTNERS_BENEFIT],
    [CMS_MARKETING_USP_BENEFITS]: [CMS_MARKETING_USP_BENEFIT],
    [CMS_FAQ_LIST]: [CMS_FAQ_LIST_ITEM, CMS_BASE_PARAGRAPH],
    [CMS_FAQ_LIST_ITEM]: [CMS_TEXT],
    [CMS_MARKETPLACE_COOPERATION_WITH_MODIVO]: [CMS_BASE_PARAGRAPH],
    [CMS_FOOTER_LINKS]: [CMS_FOOTER_LINK],
    [CMS_BASE_FOOTER_LINKS]: [CMS_FOOTER_LINK_BOTTOM],
    [CMS_FOOTER_CONFIGURATION]: [CMS_FOOTER_LINKS, CMS_BASE_FOOTER_LINKS],
    [CMS_MARKETING_PROMO_SLIDER]: [CMS_MARKETING_PRODUCTS_SLIDER],
    [CMS_PAGE_WITH_ACCORDIONS]: [CMS_STATIC_BLOCK, CMS_ZOWIE_FORM],
    [CMS_MARKETING_SIMPLE_PICTURE_BOX_GRID]: [CMS_MARKETING_SIMPLE_PICTURE_BOX],
    [CMS_BRAND_SHOP_LIST]: [CMS_BRAND_SHOP_ITEM],
    [CMS_APP]: [
        CMS_APP_HOME_SCREEN_V2,
        CMS_APP_PAGE_SCREEN_V2,
        CMS_APP_PRODUCT_SCREEN_V2,
        CMS_APP_PRODUCT_LISTING_SCREEN_V2,
        CMS_APP_SEARCH_MENU_SCREEN_V2,
        CMS_APP_SEARCH_SCREEN_V2,
        CMS_APP_CART_SCREEN_V2,
        CMS_APP_FAVOURITES_SCREEN_V2,
    ],
    [CMS_APP_HOME_SCREEN_V2]: {
        items: {
            components: [
                CMS_APP_IMAGE_CAROUSEL_V2,
                CMS_APP_SMALL_CAROUSEL_V2,
                CMS_APP_BANNER_WITH_SMALL_CAROUSEL_V2,
                CMS_APP_SMALL_IMAGE_GRID_V2,
                CMS_APP_BENEFIT_LIST_V2,
                CMS_APP_SOCIAL_MEDIA_V2,
                CMS_APP_MARKETING_BAR_V2,
                CMS_APP_BANNER_V2,
                CMS_APP_MAIN_CATEGORY_SWITCHER_LIST_V2,
            ],
        },
    },
    [CMS_APP_PAGE_SCREEN_V2]: {
        items: {
            components: [
                CMS_APP_HEADER_V2,
                CMS_APP_BANNER_V2,
                CMS_APP_MESSAGE_ICON_BANNER_V2,
                CMS_APP_IMAGE_CAROUSEL_V2,
                CMS_APP_SMALL_CAROUSEL_V2,
                CMS_APP_BANNER_WITH_SMALL_CAROUSEL_V2,
                CMS_APP_MENU_LIST_V2,
                CMS_APP_SMALL_IMAGE_GRID_V2,
                CMS_APP_LARGE_IMAGE_GRID_V2,
                CMS_APP_BENEFIT_LIST_V2,
                CMS_APP_SOCIAL_MEDIA_V2,
                CMS_APP_MARKETING_BAR_V2,
            ],
        },
    },
    [CMS_APP_PRODUCT_LISTING_SCREEN_V2]: {
        barItems: {
            components: [CMS_APP_MARKETING_BAR_V2],
            limit: 1,
        },
        headerItems: {
            components: [CMS_APP_BANNER_V2, CMS_APP_HEADER_V2],
        },
        items: {
            components: [CMS_APP_BANNER_V2, CMS_APP_IMAGE_CAROUSEL_V2, CMS_APP_SMALL_CAROUSEL_V2],
        },
        emptyListingItems: {
            components: [CMS_APP_SMALL_CAROUSEL_V2],
        },
        footerListingItems: {
            components: [CMS_APP_SMALL_CAROUSEL_V2],
        },
    },
    [CMS_APP_SEARCH_MENU_SCREEN_V2]: {
        slotHeaderItems: { components: [CMS_APP_MENU_LIST_V2] },
        slotFooterItems: { components: [CMS_APP_MENU_LIST_V2] },
    },
    [CMS_APP_SEARCH_SCREEN_V2]: {
        items: {
            components: [CMS_APP_SMALL_CAROUSEL_V2],
        },
    },
    [CMS_APP_CART_SCREEN_V2]: {
        addToCartSuccessItems: {
            components: [CMS_APP_SMALL_CAROUSEL_V2],
        },
        emptyCartItems: {
            components: [CMS_APP_SMALL_CAROUSEL_V2],
        },
    },
    [CMS_APP_FAVOURITES_SCREEN_V2]: {
        emptyFavouritesItems: {
            components: [CMS_APP_SMALL_CAROUSEL_V2],
        },
    },
    [CMS_APP_HEADER_V2]: {
        counter: { components: [CMS_APP_COUNTER_V2], limit: 1 },
        headerInfo: { components: [CMS_APP_HEADER_INFO_V2], limit: 1 },
        visibleRules: { components: [CMS_APP_VISIBLE_RULES_V2], limit: 1 },
    },
    [CMS_APP_BANNER_V2]: {
        header: { components: [CMS_APP_HEADER_V2], limit: 1 },
        visibleRules: { components: [CMS_APP_VISIBLE_RULES_V2], limit: 1 },
    },
    [CMS_APP_LARGE_CAROUSEL_V2]: {
        header: { components: [CMS_APP_HEADER_V2], limit: 1 },
        visibleRules: { components: [CMS_APP_VISIBLE_RULES_V2], limit: 1 },
    },
    [CMS_APP_SMALL_CAROUSEL_V2]: {
        header: { components: [CMS_APP_HEADER_V2], limit: 1 },
        visibleRules: { components: [CMS_APP_VISIBLE_RULES_V2], limit: 1 },
    },
    [CMS_APP_MENU_LIST_V2]: {
        header: { components: [CMS_APP_HEADER_V2], limit: 1 },
        listItem: { components: [CMS_APP_MENU_LIST_ITEM_V2] },
        visibleRules: { components: [CMS_APP_VISIBLE_RULES_V2], limit: 1 },
    },
    [CMS_APP_BENEFIT_LIST_V2]: {
        header: { components: [CMS_APP_HEADER_V2], limit: 1 },
        listItem: { components: [CMS_APP_BENEFIT_LIST_ITEM_V2] },
        visibleRules: { components: [CMS_APP_VISIBLE_RULES_V2], limit: 1 },
    },
    [CMS_APP_SMALL_IMAGE_GRID_V2]: {
        header: { components: [CMS_APP_HEADER_V2], limit: 1 },
        items: {
            components: [CMS_APP_IMAGE_GRID_ITEM_V2, CMS_APP_IMAGE_GRID_ITEM_TEXT_V2],
        },
        visibleRules: { components: [CMS_APP_VISIBLE_RULES_V2], limit: 1 },
    },
    [CMS_APP_LARGE_IMAGE_GRID_V2]: {
        header: { components: [CMS_APP_HEADER_V2], limit: 1 },
        items: {
            components: [CMS_APP_IMAGE_GRID_ITEM_V2, CMS_APP_IMAGE_GRID_ITEM_TEXT_V2],
        },
        visibleRules: { components: [CMS_APP_VISIBLE_RULES_V2], limit: 1 },
    },
    [CMS_APP_MAIN_CATEGORY_SWITCHER_LIST_V2]: {
        listItem: {
            components: [CMS_APP_MAIN_CATEGORY_SWITCHER_LIST_ITEM_V2],
        },
        visibleRules: { components: [CMS_APP_VISIBLE_RULES_V2], limit: 1 },
    },
    [CMS_APP_IMAGE_CAROUSEL_V2]: {
        header: { components: [CMS_APP_HEADER_V2], limit: 1 },
        carouselItem: { components: [CMS_APP_IMAGE_CAROUSEL_ITEM_V2] },
        visibleRules: { components: [CMS_APP_VISIBLE_RULES_V2], limit: 1 },
    },
    [CMS_APP_BANNER_WITH_SMALL_CAROUSEL_V2]: {
        header: { components: [CMS_APP_HEADER_V2], limit: 1 },
        banner: { components: [CMS_APP_BANNER_V2], limit: 1 },
        smallCarousel: { components: [CMS_APP_SMALL_CAROUSEL_V2], limit: 1 },
        visibleRules: { components: [CMS_APP_VISIBLE_RULES_V2], limit: 1 },
    },
    [CMS_APP_MARKETING_BAR_V2]: {
        counter: { components: [CMS_APP_COUNTER_V2], limit: 1 },
        visibleRules: { components: [CMS_APP_VISIBLE_RULES_V2], limit: 1 },
    },
    [CMS_APP_PRODUCT_SCREEN_V2]: {
        items: {
            components: [CMS_APP_MESSAGE_ICON_BANNER_V2, CMS_APP_LARGE_CAROUSEL_V2],
        },
    },
    [CMS_APP_BANNER_WITH_LARGE_CAROUSEL_V2]: {
        header: { components: [CMS_APP_HEADER_V2], limit: 1 },
        banner: { components: [CMS_APP_BANNER_V2], limit: 1 },
        largeCarousel: { components: [CMS_APP_LARGE_CAROUSEL_V2], limit: 1 },
        visibleRules: { components: [CMS_APP_VISIBLE_RULES_V2], limit: 1 },
    },

    [CMS_APP_MESSAGE_ICON_BANNER_V2]: {
        visibleRules: { components: [CMS_APP_VISIBLE_RULES_V2], limit: 1 },
    },

    [CMS_APP_SOCIAL_MEDIA_V2]: {
        visibleRules: { components: [CMS_APP_VISIBLE_RULES_V2], limit: 1 },
    },

    [CMS_MARKETING_BAR_TEXT_AND_LINKS]: [CMS_MARKETING_BAR_LINK],

    [CMS_MARKETING_SLIDER_SECTION]: [
        CMS_MARKETING_PROMO_CATEGORIES,
        CMS_MARKETING_PROMO_CATEGORIES_SLIDER,
    ],

    [CMS_MARKETING_PROMO_CATEGORIES]: [CMS_MARKETING_PROMO_CATEGORIES_SLIDER],

    [CMS_MARKETING_PROMO_CATEGORIES_SLIDER]: [CMS_MARKETING_PROMO_CATEGORIES_SLIDER_ITEM],
};

export const COMPONENT_TEXTAREA_PROPS = {
    [CMS_TEXT]: ['text'],
    [CMS_STATIC_BLOCK]: ['script', 'content'],
    [CMS_PAGE_WITH_ACCORDIONS]: ['script', 'content'],
};

export const COMPONENT_MULTISELECT_PROPS = {
    [CMS_GLOBAL_CONFIG]: ['zowieChatEnabledPages'],
    [CMS_SMART_BANNER_CONFIG]: ['enabledPages'],
};

export const COMPONENT_NUMBER_PROPS = {
    [CMS_APP_LARGE_CAROUSEL_V2]: ['maxItems'],
    [CMS_APP_SMALL_CAROUSEL_V2]: ['maxItems'],
    [CMS_APP_PRODUCT_LISTING_SCREEN_V2]: ['firstItemOffset', 'displayItems', 'spacedBy'],
    [CMS_ZOWIE_FORM]: ['sessionTimeout'],
};

export const VALID_INTERPRETER_ROOT_TAGS = ['div', 'span', 'ul'];

export const CMS_TEXT_ALLOWED_HTML_TAGS = ['b', 'br'];

export default {
    CMS_COMPONENTS_PREFIX,
};
